<template>
  <div>
    <div class="container">
      <br>
      <h1>Apprenez à coder à Paris</h1>
      <p>En ligne ou en face-à-face avec un professionnel</p>
      <hr>
      <div class="row">
        <div class="col-12 col-md-6">
          <p><b-img width="30px" alt="Cours de code" :src="require('@/assets/images/icons/icons8-interactive-66.png')"></b-img> Cours <strong>interactifs et ludiques</strong> !</p>
          <p><b-img width="30px" alt="Cours de code" :src="require('@/assets/images/icons/icons8-online-coding-48.png')"></b-img> Apprenez les langages de programmation les plus populaires.</p>
          <p><b-img width="30px" alt="Cours de code" :src="require('@/assets/images/icons/icons8-web-48.png')"></b-img> Développerez des projets <strong>concrets</strong> pour renforcer vos <strong>compétences</strong>.</p>
          <b-button block v-b-modal.modal-1 variant="success" @click="onClickCTA(1)">En savoir plus</b-button>
        </div>
        <div class="col-12 col-md-6">
          <b-img thumbnail width="300px" alt="Cours de code" :src="require('@/assets/images/code-course/code_course_2.jpg')"></b-img>
        </div>

      </div>

      <hr>
      <h3>Comment ça se passe ?</h3>

      <p><b-img width="30px" alt="Cours de code" :src="require('@/assets/images/icons/icons8-hours-64.png')"></b-img> Les cours durent <strong>2 heures</strong> en groupe de 3 à 4 personnes.</p>
      <p><b-img width="30px" alt="Cours de code" :src="require('@/assets/images/icons/icons8-commitment-64.png')"></b-img> <strong>Pas d'engagement</strong>, vous pouvez participer à autant de sessions que vous le souhaitez.</p>

      <hr>
      <h3>Quand ?</h3>
      <p>Nous nous réunirons <strong>à partir de 18h</strong> en présentiel dans le centre de Paris ou en ligne</p>

      <b-button v-b-modal.modal-1 variant="success" @click="onClickCTA(2)">En savoir plus</b-button>

      <hr>
      <h3>Qui suis-je ?</h3>
      <div class="row">
        <div class="col-4">
          <b-img rounded="circle" thumbnail width="200px" alt="Maximilien Andile" :src="require('@/assets/images/me.png')"></b-img>
        </div>
        <div class="col">
          <p>Je suis Maximilien Andile. J'ai 33 ans. Je vis à Paris.</p>
          <p>J'ai <strong>+10 ans d'expérience</strong> et je travaille actuellement en tant que Lead Developer pour Vestiaire Collective.</p>
          <p>Coder c'est ma <strong>passion</strong> et partager mes connaissances est ma mission.</p>
          <p>Mon profil <b-link @click="onClickLink('Linkedin')" href="https://www.linkedin.com/in/maximilienandile/" target="_blank">Linkedin</b-link>.</p>
          <p>Parcourez mon <b-link @click="onClickLink('Book')" href="https://www.practical-go-lessons.com/" target="_blank">Livre</b-link> sur le langage Go.</p>
        </div>
      </div>
      <b-button block v-b-modal.modal-1 variant="success" @click="onClickCTA(3)">En savoir plus</b-button>
      <br>
      <br>
      <br>

      <b-modal id="modal-1" title="Demande d'information" hide-footer>
        <ContactFormV2 v-on:form-validated="onFormValidated" ></ContactFormV2>
      </b-modal>

    </div>
  </div>
</template>

<script>

import ContactFormV2 from "@/components/ContactFormV2.vue";
import Webservice from "@/webservice";
import Consts from "@/consts";
import ScriptLoader from "@/scriptLoader";
import ChatTrigger from "@/chatTrigger";

export default {
  name: "CodeCourse",
  components: {ContactFormV2},
  created() {
    window.addEventListener('scroll', this.handleScroll);
    ScriptLoader.loadGtag().then(()=>{
      console.log("gtag loaded")
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-10991349756');
    })
    Webservice.tracking({
      context:Consts.CONTEXT_COURSE,
      action:{
        referrer:document.referrer,
        lang: navigator.language || navigator.userLanguage,
        name:"LANDING_LOADED",
        utm: {
          source: this.$route.query.utm_source,
          medium: this.$route.query.utm_medium,
          campaign: this.$route.query.utm_campaign,
          term: this.$route.query.utm_term,
          content: this.$route.query.utm_content,
        }
      }
    })
    // chat trigger
    ChatTrigger.triggerChat("ViewSurMesure")
  },
  data() {
    return {
      userHasScrolled: false,
    }
  },
  metaInfo () {
    return {
      title: "Cours de code à Paris",
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.titleMeta },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.titleMeta},
        { property: 'og:description', content: this.descriptionMeta },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]
    }
  },
  methods:{
    onClickLink(linkName){
      this.track("CLICK_LINK",{
        linkName:linkName,
      })
    },
    handleScroll(){
      if (this.userHasScrolled === false){
        // first time that he scrolls
        console.log("has scrolled")
        this.track("SCROLL",{})
        this.userHasScrolled = true
      }
    },
    onFormValidated(){
      // send conversion event to google
      ScriptLoader.loadGtag().then(()=>{
        function gtag(){window.dataLayer.push(arguments);}
        let callback = function () {
          console.log("conversion event sent")
        };
        gtag('event', 'conversion', {
          'send_to': 'AW-10991349756/Z08ZCJ_drZoYEPzfivko',
          'event_callback': callback
        });
      })
    },
    openCrisp() {
      window.$crisp.push(['do', 'chat:open'])
    },
    track(eventName, additionalData ){
      Webservice.tracking({
        additionalData: additionalData,
        context:Consts.CONTEXT_COURSE,
        action:{
          referrer:document.referrer,
          lang: navigator.language || navigator.userLanguage,
          name:eventName,
          utm: {
            source: this.$route.query.utm_source,
            medium: this.$route.query.utm_medium,
            campaign: this.$route.query.utm_campaign,
            term: this.$route.query.utm_term,
            content: this.$route.query.utm_content,
          }
        }
      })
    },
    onClickCTA(ctaID){
      this.track("CLICK_CTA",{
        ctaID:ctaID,
      })
    }
  }
}
</script>

<style scoped>

</style>