<template>
 <div>
   <h3 id="contact-form-success-sent">Merci !</h3>
   <div class="text-center">
     <p>Nous avons bien pris en compte votre demande !</p>
   </div>
   <div class="text-center mb-5 mt-5">
     <p>Nous vous contactons dès que possible</p>
   </div>
 </div>
</template>

<script>
export default {
name: "ContactSuccess",
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>