import Webservice from "@/webservice";


function triggerChat(name){
   try {
       setTimeout(function(){
           let sessionId = window.$crisp.get("session:identifier")
           if (sessionId !== null){
               Webservice.triggerChat({
                   crispSessionId:sessionId,
                   name:name,
                   sendAfter: "3ms"
               })
           }
       }, 3000);
    }catch (e){
       console.error(e);
   }
}


export default {
    triggerChat: triggerChat,
}
