<template>
  <div>
    <div class="row">
      <div class="col" >
        <form @submit="onSubmit" v-if="show">

          <MazPhoneNumberInput
              v-model="form.phoneNumber"
              show-code-on-list

              color="info"
              :preferred-countries="['FR']"
              :ignored-countries="['AC']"
              :translations="{
                countrySelectorLabel: 'Code pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone',
                example: 'Exemple :'
              }"
              @update="onUpdatePhoneNumber"
          />
          <small><i class="bi bi-lock"></i> Nous ne partagerons avec personnes votre numéro de téléphone</small>
          <div class="mb-3">
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="Message  (optionnel)"></textarea>
          </div>
          <b-button variant="info" class="mb-2" type="submit" block>
            <i class="bi bi-check"></i>
            Envoyer
          </b-button>
        </form>
        <ContactSuccess v-else></ContactSuccess>
      </div>
    </div>

  </div>
</template>

<script>
import Webservice from "@/webservice";
import ContactSuccess from "@/components/ContactSuccess";
import {MazPhoneNumberInput}  from 'maz-ui'
import Consts from "@/consts";


export default {
name: "ContactFormV2",
  components: {ContactSuccess,MazPhoneNumberInput},
  data() {
    return {
      showPhoneTooltip: false,
      disablePhoneTooltip: true,
      defaultCountryCode: "FR",
      phoneNumberInputTranslations: {
        countrySelectorLabel: 'Code pays',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Numéro de téléphone',
        example: 'Exemple :'
      },
      form: {
        phoneNumberParsed: '',
        email: 'not.mandatory@email.com',
        name: '',
        message: '',
        context:'course'
      },
      show: true
    }
  },
  props:{
    context: String
  },

  methods: {
    registerAction(actionName, context){
      Webservice.tracking({
        context: context,
        action:{
          referrer:document.referrer,
          lang: navigator.language || navigator.userLanguage,
          name:actionName,
          utm: {
            source: this.$route.query.utm_source,
            medium: this.$route.query.utm_medium,
            campaign: this.$route.query.utm_campaign,
            term: this.$route.query.utm_term,
            content: this.$route.query.utm_content,
          }
        }
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    onUpdatePhoneNumber(data) {
      this.showPhoneTooltip = false
      this.disablePhoneTooltip = true
      this.form.phoneNumberParsed = data
      if (data.isValid) {
        this.registerAction("PHONE_INPUTED", Consts.CONTEXT_COURSE)
      }
    },
    onSubmit(evt) {
      evt.preventDefault()
      if (this.form.phoneNumberParsed === undefined || !this.form.phoneNumberParsed.isValid) {
        this.disablePhoneTooltip = false
        this.showPhoneTooltip = true
        return
      }
      Webservice.createContact(this.form).then(()=>{
        this.show = false
        this.makeToast("Success !", "Thanks I will get back to you ASAP", "success")
        this.registerAction("FORM_VALIDATED", Consts.CONTEXT_COURSE)
        this.$emit('form-validated')
      }).catch((err)=>{
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        this.registerAction("FORM_ERROR", Consts.CONTEXT_COURSE)
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>
